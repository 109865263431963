import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Container,
    SecondaryButton,
    Header,
    DropDown,
    GenericModal,
    Input, Label, CheckBox, Colors, Link, TextArea, getColor, Opacity, Radius, Spinner
} from 'metrix-common';

import ActionsMenuConfig from '../ActionsMenu/configs';
import { getAndUseRoute } from '../../../../utils';

const MenuContainer = styled(Container)`
    z-index: 1;
    margin-top: -25px;
`;

const CustomContainer = styled(Container)`
    height: 10rem;
    max-width: 368px;
    padding: 0 1rem;
    border: 1px solid ${Colors.StrokeDark};
    border-radius: 5px;
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
`;

const StyledTextArea = styled(TextArea)`
    border: 1px solid ${getColor(Colors.StrokeDark, Opacity.None)};
    border-radius: ${Radius.small};
    //padding: .25rem;
    //height: 150px;
`;

const SpaceBetweenContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
`;

class ProfileMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lostModal: false,
            rejectModal: false,
            confirmBindModal: false,
            issueQuoteModal: false,
            issuePolicyModal: false,
            shouldProposalEmail: false,
            sendPaymentLinkModal: false,
            paymentLinkConfirmationModal: false,
            selectedDocuments: [],
            action: null,
            ccRecipients: '',
            userAddedNoteForEmail: ''
        };
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.state.issuePolicyModal && prevState.issuePolicyModal !== this.state.issuePolicyModal) || (this.state.issueQuoteModal && prevState.issueQuoteModal !== this.state.issueQuoteModal)) {
            this.props.document.doQueryShareableDocuments(this.props.entity);
        }
    }

    sortMenuByStatus = (Configs, status, type, role) => {
        const menu = Configs.filter(obj => {
            if (obj.type === type) {
                return (obj.status === status) && (obj.role === role) && (obj.type === type);
            } else {
                return (obj.status === status) && (obj.role === role) && (obj.type === null);
            }
        });

        if (menu.length > 1) {
            return menu.filter(obj => obj.type !== null);
        } else {
            return menu;
        }
    };

    getAvailableActions = (menu, product, role, renewalPolicy) => {
        if (menu && menu[0] && menu[0].actions) {
            return menu[0].actions.filter(action => {

                let available = true;

                if ((action.applicableProducts && !action.applicableProducts.includes(product)) ||
                    (action.excludedProducts && action.excludedProducts.includes(product)) ||
                    (action.excludedRoles && action.excludedRoles.includes(role))) {
                    available = false;
                }
                if (action.code === 'ViewRenewalPolicy' && !renewalPolicy) {
                    available = false;
                }

                return available;
            });
        }

        return null;
    };

    handleClick = (action) => {
        if (action.code === 'Lost') {
            this.setState({ lostModal: true });
        } else if (action.code === 'RejectSubmission') {
            this.setState({ rejectModal: true });
        } else if (action.code === 'ConfirmBind' && !action?.MGAProducts.some(x => x.includes(this.props.policy.product))) {
            this.setState({ confirmBindModal: true });
        } else if (action.code === 'ConfirmBind') {
            this.props.doExecutePolicyAction({ code: 'ConfirmBind' });
        } else if (action.code === 'ContinueQuote') {
            this.handleContinueQuoteClick(null, { siteCode: 'corporateWeb', path: 'workflow', entity: this.props.policySummary.guid, isBlankPage: true });
        } else {
            this.props.doExecutePolicyAction(action);
        }
    };

    handleContinueQuoteClick = async (e, payload) => {
        await getAndUseRoute(e, this.props.context.session, payload);
    };

    checkPolicNoValidation = (policyNo) => {
        let regEx = /^[A-Za-z0-9.-]*$/;
        return policyNo && policyNo.length <= 20 && regEx.test(policyNo);
    };

    handleDocumentSelect = (guid) => {
        let selectedDocuments = this.state.selectedDocuments;
        if (selectedDocuments.includes(guid)) {
            selectedDocuments = selectedDocuments.filter(x => x !== guid);
        } else {
            selectedDocuments.push(guid);
        }
        this.setState({ selectedDocuments });
    };

    handleIssueQuoteModalCancel = () => {
        this.setState({
            issueQuoteModal: false,
            issuePolicyModal: false,
            selectedDocuments: []
        });
    }

    handleIssueQuoteModalOk = () => {
        this.props.doExecutePolicyTransactionCustomAction(
            {
                ccRecipients: this.state.ccRecipients,
                documentAttachmentGuids: this.state.selectedDocuments,
                sendEmailToClient: this.state.shouldProposalEmail,
                userAddedNoteForEmail: this.state.userAddedNoteForEmail,
                policyTransactionGuid: this.props.entity
            }
        )
        this.setState({
            issueQuoteModal: false,
            selectedDocuments: [],
            ccRecipients: '',
            userAddedNoteForEmail: ''
        });
    }

    handleIssuePolicyModalCancel = () => {
        this.setState({
            issuePolicyModal: false,
            selectedDocuments: []
        });
    }

    handleIssuePolicyModalOk = () => {
        this.props.doReduceParameters([{
            code: 'SendEmailToClient',
            value: this.state.shouldProposalEmail
        }, {
            code: 'DocumentAttachmentGuids',
            value: this.state.selectedDocuments.join('|')
        }]);
        this.handleClick(this.state.action)
        this.handleIssuePolicyModalCancel();
    }

    paymentLinkConfirmationCloseHandler = () => {
	    this.setState({ paymentLinkConfirmationModal: false });
        window.location.reload();
    }

    render() {
        let renewalPolicy = this.props?.policySummary?.renewalPolicyReference?.guid ? this.props.policySummary.renewalPolicyReference.guid : false;
        const sortedMenu = this.sortMenuByStatus(ActionsMenuConfig, this.props.policySummary.status, this.props.policySummary.type, this.props.context.session.userType);
        const actions = this.getAvailableActions(sortedMenu, this.props.policySummary.product, this.props.policySummary.role, renewalPolicy) || [];
        const policyNo = this.props.policy && this.props.policy.number
        const validPolicyNo = this.checkPolicNoValidation(policyNo);

        const isIndicativeQuote = this.props.policySummary.isIndicativeQuote;
        const isFinalizeQuote = (this.props.policySummary.status === 'quoted' || this.props.policySummary.status === 'quoteAccepted') && isIndicativeQuote;
        const isFinalizeQuoteAction = {
            code: 'ReQuote',
            hooks: [],
            excludedProducts: ['ppackage']
        }

        const loading = this.props.context.loading;
        const apiStatus = this.props.context.operation?.status;
        const apiMessage = this.props.context.operation?.errors && this.props.context.operation?.errors.length ? this.props.context.operation?.errors[0].value : '';
        let headerAndButtonLabel;
        let proposalEmailCheckboxLabel;

        const renderSecondaryButton = (action, text, clickEvent) => {
            return (
                <SecondaryButton key={action.code}
                                 left={!this.context.screen.isMobile ? Sizes.xs : Sizes.None}
                                 bottom={!this.context.screen.isMobile ? Sizes.None : Sizes.xs}
                                 text={text}
                                 clickEvent={clickEvent}
                />
            );
        };

        return (
            <Container>
                {
                    <MenuContainer top={Sizes.xs}
                                   bottom={Sizes.xs}
                                   inline={!this.context.screen.isMobile}
                                   horizontalAlignment={Alignments.center}
                    >
                        {
                            (actions && !isFinalizeQuote) && actions.map(action => {
                                switch (action.code) {
                                    case 'ViewRenewalPolicy':
                                        return renderSecondaryButton(
                                            action,
                                            <FormattedMessage id={'View Renewal Policy'} />,
                                            () => window.location = `${this.props.context.configuration.policyWeb}/profile?entity=${renewalPolicy}`
                                        );
                                    case 'IssueQuote':
                                        headerAndButtonLabel = 'Issue Quote';
                                        proposalEmailCheckboxLabel = 'Send Proposal Email';
                                        return renderSecondaryButton(action, 'Issue Quote', () => this.setState({
                                            issueQuoteModal: true,
                                            action: action
                                        }));
                                    case 'Issue':
                                        headerAndButtonLabel = 'Issue Policy';
                                        proposalEmailCheckboxLabel = 'Send Policy Confirmation';
                                        return renderSecondaryButton(action, 'Issue Policy', () => this.setState({
                                            issuePolicyModal: true,
                                            action: action
                                        }));
                                    case 'SendPaymentLink':
                                        if (this.props.policy?.allowSendPaymentLinkAction) {
                                            return renderSecondaryButton(action, 'Send Payment Link', () => {
                                                this.props.doGetPaymentEmail();
                                                this.setState({
                                                    sendPaymentLinkModal: true,
                                                    action: action
                                                })
                                            });
                                        }
                                        break;
                                    default:
                                        return renderSecondaryButton(
                                            action,
                                            action.text ? `${action.text}` :
                                                <FormattedMessage id={`app.components.ActionsMenu.${(action.code === 'ConfirmBind' && action?.MGAProducts.some(x => x.includes(this.props.policy.product))) ? 'Bind' : action.code}`} />,
                                            () => this.handleClick(action)
                                        );
                                }
                            })
                        }
                        {
                            isFinalizeQuote && renderSecondaryButton(
                                isFinalizeQuoteAction,
                                'Finalize Quote',
                                () => this.handleClick(isFinalizeQuoteAction)
                            )
                        }
                    </MenuContainer>
                }
                {
                    this.state.issueQuoteModal &&
                    <GenericModal
                        animate
                        visible
                        closeEvent={this.handleIssueQuoteModalCancel}
                        items={
                            <React.Fragment>
                                <Header text={headerAndButtonLabel}
                                        size={Sizes.md}
                                        bottom={Sizes.md}
                                />
                                <Container>
                                    <CheckBox label={proposalEmailCheckboxLabel}
                                              value={this.state.shouldProposalEmail}
                                              clickEvent={() => this.setState(prevState => ({
                                                  shouldProposalEmail: !prevState.shouldProposalEmail
                                              }))}
                                              bottom={Sizes.sm}
                                    />
                                    {
                                        this.state.shouldProposalEmail && this.props.document?.shareableDocuments && this.props.document?.shareableDocuments.length > 0 &&
                                        <>
                                            <Label text='Select documents to attach:'
                                                   textAlign={Alignments.left}
                                                   size={Sizes.lg}
                                                   bottom={Sizes.xs}
                                                   bold
                                            />
                                            <CustomContainer>
                                                {
                                                    this.props.document?.shareableDocuments.map(document => (
                                                        <SpaceBetweenContainer inline verticalAlignment={Alignments.center}>
                                                            <CheckBox label={document.displayName}
                                                                      size={Sizes.xs}
                                                                     key={document.guid}
                                                                     value={this.state.selectedDocuments.includes(document.guid)}
                                                                     clickEvent={() => this.handleDocumentSelect(document.guid)}
                                                                     bottom={Sizes.xs}
                                                            />
                                                            <Link text='Download' size={Sizes.xs} clickEvent={() => this.props.document.doGetDocument(document.guid)} />
                                                        </SpaceBetweenContainer>
                                                    ))
                                                }
                                            </CustomContainer>
                                        </>
                                    }
                                    <Label text='Add a CC:'
                                           textAlign={Alignments.left}
                                           size={Sizes.lg}
                                           top={Sizes.sm}
                                           bottom={Sizes.xs}
                                           bold
                                    />
                                    <Input value={this.state.ccRecipients}
                                           placeholder='Enter email addresses separated by comma or semicolon'
                                           changeEvent={(value) => this.setState({ ccRecipients: value })} bottom={Sizes.sm}
                                    />
                                    <Label text='Add a note:'
                                           textAlign={Alignments.left}
                                           size={Sizes.lg}
                                           bottom={Sizes.xs}
                                           bold
                                    />
                                    <StyledTextArea value={this.state.userAddedNoteForEmail}
                                                    placeholder='Enter your note here'
                                                    changeEvent={(value) => this.setState({ userAddedNoteForEmail: value })}
                                                    bottom={Sizes.sm}
                                    />
                                    <Label text='The insured will receive an email containing any attachment you have included, along with any notes you have added to the notes area above.'
                                           textAlign={Alignments.left}
                                           size={Sizes.lg}
                                           bottom={Sizes.sm}
                                    />
                                </Container>
                            </React.Fragment>
                        }
                        actionButtons={[
                            {
                                primary: false,
                                text: 'Cancel',
                                disabled: false,
                                clickEvent: this.handleIssueQuoteModalCancel
                            },
                            {
                                primary: true,
                                text: 'Issue',
                                disabled: false,
                                clickEvent: this.handleIssueQuoteModalOk
                            }
                        ]}
                    />
                }
                {
                    this.state.issuePolicyModal &&
                    <GenericModal
                        animate
                        visible
                        closeEvent={this.handleIssuePolicyModalCancel}
                        items={
                            <React.Fragment>
                                <Header text={headerAndButtonLabel}
                                        size={Sizes.md}
                                        bottom={Sizes.md}
                                />
                                <Container>
                                    <CheckBox label={proposalEmailCheckboxLabel}
                                              value={this.state.shouldProposalEmail}
                                              clickEvent={() => this.setState(prevState => ({
                                                  shouldProposalEmail: !prevState.shouldProposalEmail
                                              }))}
                                              bottom={Sizes.sm}
                                    />
                                    {
                                        this.state.shouldProposalEmail && this.props.document?.shareableDocuments && this.props.document?.shareableDocuments.length > 0 &&
                                        <>
                                            <Label text='Select documents to attach:'
                                                   textAlign={Alignments.left}
                                                   size={Sizes.lg}
                                                   bottom={Sizes.xs}
                                                   bold
                                            />
                                            <CustomContainer>
                                                {
                                                    this.props.document?.shareableDocuments.map(document => (
                                                        <SpaceBetweenContainer inline verticalAlignment={Alignments.center}>
                                                            <CheckBox label={document.displayName}
                                                                      size={Sizes.xs}
                                                                     key={document.guid}
                                                                     value={this.state.selectedDocuments.includes(document.guid)}
                                                                     clickEvent={() => this.handleDocumentSelect(document.guid)}
                                                                     bottom={Sizes.xs}
                                                            />
                                                            <Link text='Download' size={Sizes.xs} clickEvent={() => this.props.document.doGetDocument(document.guid)} />
                                                        </SpaceBetweenContainer>
                                                    ))
                                                }
                                            </CustomContainer>
                                        </>
                                    }
                                </Container>
                            </React.Fragment>
                        }
                        actionButtons={[
                            {
                                primary: false,
                                text: 'Cancel',
                                disabled: false,
                                clickEvent: this.handleIssuePolicyModalCancel
                            },
                            {
                                primary: true,
                                text: 'Issue',
                                disabled: false,
                                clickEvent: this.handleIssuePolicyModalOk
                            }
                        ]}
                    />
                }
                {
                    this.state.lostModal &&
                    <GenericModal
                        animate
                        visible
                        closeEvent={() => {
                            this.props.doReduceParameters([]);
                            this.setState({ lostModal: false });
                        }}
                        items={
                            <React.Fragment>
                                <Header size={Sizes.md}
                                        text={'Lose Lead'}
                                        bottom={Sizes.xs}
                                />
                                <DropDown
                                    top={Sizes.xs}
                                    placeholder={'Reason'}
                                    label={`Why didn't we win?`}
                                    value={{ code: this.props.parameters && this.props.parameters.some(x => x.code === 'LostReason') && this.props.parameters.find(x => x.code === 'LostReason').value }}
                                    references={this.props.reference.references['LostReason']}
                                    referencesEvent={() => this.props.reference.doGetReferences('LostReason')}
                                    changeEvent={(value) => {
                                        this.props.doReduceParameter({ code: 'LostReason', value: value.code });
                                    }}
                                    allowEdit
                                />
                            </React.Fragment>
                        }
                        actionButtons={[
                            {
                                text: <FormattedMessage id='covera.common.general.cancel' />,
                                clickEvent: () => {
                                    this.props.doReduceParameters([]);
                                    this.setState({ lostModal: false });
                                }
                            },
                            {
                                primary: true,
                                text: <FormattedMessage id='covera.common.general.ok' />,
                                disabled: !(this.props.parameters && this.props.parameters.some(x => x.code === 'LostReason') && this.props.parameters.find(x => x.code === 'LostReason').value),
                                clickEvent: () => {
                                    this.props.doExecutePolicyAction({ code: 'Lost' });
                                    this.setState({ lostModal: false });
                                }
                            }
                        ]}
                    />
                }
                {
                    this.state.rejectModal &&
                    <GenericModal
                        animate
                        visible
                        closeEvent={() => {
                            this.props.doReduceParameters([]);
                            this.setState({ rejectModal: false });
                        }}
                        items={
                            <React.Fragment>
                                <Header size={Sizes.md}
                                        text={'Reject Policy'}
                                        bottom={Sizes.xs}
                                />
                                <DropDown
                                    top={Sizes.xs}
                                    placeholder={'Reason'}
                                    label={`Why didn't we want this lead?`}
                                    value={{ code: this.props.parameters && this.props.parameters.some(x => x.code === 'RejectedReason') && this.props.parameters.find(x => x.code === 'RejectedReason').value }}
                                    references={this.props.reference.references['RejectedReason']}
                                    referencesEvent={() => this.props.reference.doGetReferences('RejectedReason')}
                                    changeEvent={(value) => {
                                        this.props.doReduceParameter({ code: 'RejectedReason', value: value.code });
                                    }}
                                    allowEdit
                                />
                            </React.Fragment>
                        }
                        actionButtons={[
                            {
                                text: <FormattedMessage id='covera.common.general.cancel' />,
                                clickEvent: () => {
                                    this.props.doReduceParameters([]);
                                    this.setState({ rejectModal: false });
                                }
                            },
                            {
                                primary: true,
                                text: <FormattedMessage id='covera.common.general.ok' />,
                                disabled: !(this.props.parameters && this.props.parameters.some(x => x.code === 'RejectedReason') && this.props.parameters.find(x => x.code === 'RejectedReason').value),
                                clickEvent: () => {
                                    this.props.doExecutePolicyAction({ code: 'RejectSubmission' });
                                    this.setState({ rejectModal: false });
                                }
                            }
                        ]}
                    />
                }
                {
                    this.state.confirmBindModal &&
                    <GenericModal
                        animate
                        visible
                        closeEvent={() => {
                            this.props.doReduceParameters([]);
                            this.setState({ confirmBindModal: false });
                        }}
                        items={
                            <React.Fragment>
                                <Header size={Sizes.md}
                                        text={'Confirm Bind'}
                                        bottom={Sizes.xs}
                                />
                                <Input
                                    label={'Enter Policy Number'}
                                    value={policyNo ? policyNo : ''}
                                    changeEvent={(value) => {
                                        let isValid = this.checkPolicNoValidation(value) || value.length === 0;
                                        if (isValid) {
                                            this.props.doReducePolicyElement({ code: 'number', value: value });
                                        }
                                    }}
                                    error={!validPolicyNo}
                                    allowEdit
                                />
                            </React.Fragment>
                        }
                        actionButtons={[
                            {
                                text: <FormattedMessage id='covera.common.general.cancel' />,
                                clickEvent: () => {
                                    this.props.doReduceParameters([]);
                                    this.setState({ confirmBindModal: false });
                                }
                            },
                            {
                                primary: true,
                                text: <FormattedMessage id='covera.common.general.ok' />,
                                disabled: !validPolicyNo,
                                clickEvent: () => {
                                    this.props.doExecutePolicyAction({ code: 'ConfirmBind' });
                                    this.setState({ confirmBindModal: false });
                                    window.location.reload();
                                }
                            }
                        ]}
                    />
                }
                {
                    this.state.sendPaymentLinkModal &&
                    <GenericModal
                        animate
                        visible
                        closeEvent={() => {
                            this.setState({ sendPaymentLinkModal: false });
                        }}
                        items={
                            <React.Fragment>
                                <Header size={Sizes.md}
                                        text={'Send Payment Link'}
                                        textAlign={Alignments.left}
                                        top={Sizes.md}
                                        bottom={Sizes.sm}
                                />
                                <Label text={'Your customer will receive an email with a link to pay for their policy.'}
                                       textAlign={Alignments.left}
                                       size={Sizes.lg}
                                       bottom={Sizes.sm}
                                       bold
                                />
                                <Label text={'If successful, the policy will automatically bound and documents will be generated and made available to the insured in Customer portal branded by your brokerage.'}
                                       textAlign={Alignments.left}
                                       size={Sizes.md}
                                       bottom={Sizes.sm}
                                />
                                <Label text={'This can\'t be undone. Are you sure?'}
                                       textAlign={Alignments.left}
                                       size={Sizes.md}
                                       bottom={Sizes.sm}
                                />
                                {
                                    loading ? (
                                        <Container fullWidth horizontalAlignment={Alignments.center}>
                                            <Spinner size={Sizes.xl} />
                                        </Container>
                                    ) : <Input
                                        value={this.props.paymentEmail}
                                        changeEvent={this.props.doReducePaymentEmail}
                                        type='email'
                                        bottom={Sizes.md}
                                        allowEdit
                                    />
                                }
                            </React.Fragment>
                        }
                        actionButtons={[
                            {
                                text: 'Cancel',
                                clickEvent: () => {
                                    this.setState({ sendPaymentLinkModal: false });
                                }
                            },
                            {
                                primary: true,
                                text: 'Confirm',
                                disabled: loading,
                                clickEvent: () => {
                                    this.props.doSendPaymentLink();
                                    this.setState({ sendPaymentLinkModal: false });
                                    this.setState({ paymentLinkConfirmationModal: true });
                                }
                            }
                        ]}
                    />
                }
                {
                    this.state.paymentLinkConfirmationModal && !loading &&
                    <GenericModal
                        animate
                        visible
                        closeEvent={this.paymentLinkConfirmationCloseHandler}
                        items={
                            <>
                                <Header size={Sizes.md}
                                        text={apiStatus ? 'Success!' : 'Error!'}
                                        textAlign={Alignments.left}
                                        top={Sizes.md}
                                        bottom={Sizes.sm}
                                />
                                <Label text={apiStatus ? 'A payment link has been sent to your customer to complete.' : 'We are unable to send a payment link. Please check for validation errors.'}
                                        textAlign={Alignments.left}
                                        size={Sizes.lg}
                                        bottom={Sizes.sm}
                                       bold
                                />
                                <Label text={apiStatus ? 'You will be notified by email once the payment is complete and the policy will be automatically bound.' : apiMessage}
                                       textAlign={Alignments.left}
                                       size={Sizes.md}
                                       bottom={Sizes.sm}
                                />
                                {
                                    apiStatus &&
                                    <Label text={'Follow up with your customer if you have not been notified or the policy status is set to unpaid.'}
                                           textAlign={Alignments.left}
                                           size={Sizes.md}
                                           bottom={Sizes.md}
                                    />
                                }
                            </>
                        }
                        actionButtons={[
                            {
                                primary: true,
                                text: 'Ok',
                                clickEvent: this.paymentLinkConfirmationCloseHandler
                            }
                        ]}
                    />

                }
            </Container>
        );
    }
}

ProfileMenu.contextTypes = {
    screen: PropTypes.object,
    configuration: PropTypes.object
};

export default ProfileMenu;