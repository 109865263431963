import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormDropDown, FormInput, FormSwitch, FormCustomPlaceAutocomplete } from 'metrix-common';

const ProfileSupernovaCommercialBusinessInfo = ({
    pageCode,
    doUpsertPolicy,
    doGetPolicy,
    allowEdit,
    policy,
    doReducePolicyElement,
    reference,
    tooltipInfo,
    validationInfo,
    doUpsertTooltipInfo,
    doReduceTooltipInfoElement,
    doUpdatePlace,
    context
}) => (
    <>
        <Form
            pageCode={pageCode}
            sectionCode='companyInformation'
            header='Company Information'
            tooltipInfo={tooltipInfo}
            validationInfo={validationInfo}
            saveTooltipInfo={() => doUpsertTooltipInfo(policy.product)}
            reduceTooltipInfoElement={doReduceTooltipInfoElement}
            saveEvent={doUpsertPolicy}
            cancelEvent={doGetPolicy}
            allowEdit={allowEdit}
        >
            <FormCustomPlaceAutocomplete
                querryCode='sn'
                handleClickApply={doUpdatePlace}
                fieldCode='snCommercialBusinessInfo.companyLookup'
                label='Company Lookup'
                context={context}
                allowEdit
            />
            <FormInput
                fieldCode='snCommercialBusinessInfo.doingBusinessAs'
                label='Doing Business As Name'
                value={policy.snCommercialBusinessInfo.doingBusinessAs}
                type='text'
                changeEvent={value => doReducePolicyElement({
                    code: 'snCommercialBusinessInfo.doingBusinessAs',
                    value
                })}
                allowEdit
            />
            <FormInput
                fieldCode='snCommercialBusinessInfo.legalBusinessName'
                label='Legal Business Name'
                value={policy.snCommercialBusinessInfo.legalBusinessName}
                type='text'
                changeEvent={value => doReducePolicyElement({
                    code: 'snCommercialBusinessInfo.legalBusinessName',
                    value
                })}
                allowEdit
            />
        </Form>

        <Form
            pageCode={pageCode}
            sectionCode='businessInformation'
            header='Business Information'
            tooltipInfo={tooltipInfo}
            validationInfo={validationInfo}
            saveTooltipInfo={() => doUpsertTooltipInfo(policy.product)}
            reduceTooltipInfoElement={doReduceTooltipInfoElement}
            saveEvent={doUpsertPolicy}
            cancelEvent={doGetPolicy}
            allowEdit={allowEdit}
        >
            <FormInput
                fieldCode='snCommercialBusinessInfo.fus'
                label='Fire Underwriters Survey (FUS)'
                value={policy.snCommercialBusinessInfo.fus}
                changeEvent={value => null}
                type='number'
                allowEdit={false}
            />

            <FormInput
                fieldCode='snCommercialBusinessInfo.businessInfoYearEstablished'
                label='Year business was established'
                value={policy.snCommercialBusinessInfo.businessInfoYearEstablished}
                type='number'
                maxValue={2100}
                minValue={1800}
                overrideMaxLength={4}
                changeEvent={value => {
                    const date = new Date();
                    const currentYear = date.getFullYear();
                    if (currentYear - value <= 1) {
                        doReducePolicyElement({
                            code: 'policy.snCommercialBusinessInfo.isNewBusines',
                            value: true
                        })
                    } else {
                        doReducePolicyElement({
                            code: 'policy.snCommercialBusinessInfo.isNewBusines',
                            value: false
                        })
                    }

                    doReducePolicyElement({
                        code: 'snCommercialBusinessInfo.businessInfoYearEstablished',
                        value
                    })
                }}
                allowEdit
            />

            <FormDropDown
                fieldCode='snCommercialBusinessInfo.businessInfoEmployeeCountCode'
                label='What is the number of employees?'
                value={{ code: policy.snCommercialBusinessInfo.businessInfoEmployeeCountCode }}
                references={reference?.references['sn-commercial']?.EmployeeCountGroups}
                referencesEvent={() => reference.doGetReferences('sn-commercial.EmployeeCountGroups')}
                changeEvent={value => {
                    doReducePolicyElement({
                        code: 'snCommercialBusinessInfo.businessInfoEmployeeCountCode',
                        value: value.code
                    });
                }}
                allowEdit
            />
            <FormInput
                fieldCode='snCommercialBusinessInfo.businessInfoRevenueAmount'
                label='Yearly revenue amount'
                value={policy.snCommercialBusinessInfo.businessInfoRevenueAmount}
                type='currency'
                changeEvent={value => doReducePolicyElement({
                    code: 'snCommercialBusinessInfo.businessInfoRevenueAmount',
                    value
                })}
                allowEdit
            />
            {/*If Industry Category is CEI*/}
            {
                policy.snCommercialBusinessInfo.doesClientUseSubcontractorsDisplayed &&
                <FormSwitch
                    fieldCode='snCommercialBusinessInfo.doesClientUseSubcontractors'
                    label='Does your client use sub-contractors in their business?
'
                    value={policy.snCommercialBusinessInfo.doesClientUseSubcontractors}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialBusinessInfo.doesClientUseSubcontractors',
                        value
                    })}
                    allowEdit
                />
            }
            {
                policy.snCommercialBusinessInfo.doesClientUseSubcontractorsDisplayed &&
                policy.snCommercialBusinessInfo.doesClientUseSubcontractors &&
                <FormSwitch
                    fieldCode='snCommercialBusinessInfo.doesClientUseCertificatesOfInsuranceFromSubcontractors'
                    label='Does your client obtain certificates of insurance from sub-contractors?'
                    value={policy.snCommercialBusinessInfo.doesClientUseCertificatesOfInsuranceFromSubcontractors}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialBusinessInfo.doesClientUseCertificatesOfInsuranceFromSubcontractors',
                        value
                    })}
                    allowEdit
                />
            }
        </Form>
        <Form
            pageCode={pageCode}
            sectionCode='industryInformation'
            header='Industry'
            tooltipInfo={tooltipInfo}
            validationInfo={validationInfo}
            saveTooltipInfo={() => doUpsertTooltipInfo(policy.product)}
            reduceTooltipInfoElement={doReduceTooltipInfoElement}
            saveEvent={doUpsertPolicy}
            cancelEvent={doGetPolicy}
            allowEdit={allowEdit}
        >
            <FormDropDown
                fieldCode='snCommercialBusinessInfo.industryPrimaryCode'
                label='What is the main industry of this business?'
                value={{ code: policy.snCommercialBusinessInfo.industryPrimaryCode }}
                references={reference?.references['sn-commercial']?.IndustryCodes}
                referencesEvent={() => reference.doGetReferences('sn-commercial.IndustryCodes')}
                changeEvent={value => {
                    doReducePolicyElement({
                        code: 'snCommercialBusinessInfo.industryPrimaryCode',
                        value: value.code
                    });
                    doReducePolicyElement({
                        code: 'snCommercialBusinessInfo.industryPrimaryName',
                        value: value.value
                    });
                }}
                allowEdit
            />
            <FormInput
                fieldCode='snCommercialBusinessInfo.primaryIndustryCategory'
                label='Primary Category'
                value={policy.snCommercialBusinessInfo.primaryIndustryCategory}
                type='text'
                allowEdit={false}
            />
            {/*<FormSwitch*/}
            {/*    fieldCode='snCommercialBusinessInfo.hasSecondaryIndustry'*/}
            {/*    label="Is there a second industry code that matches your customer's business?"*/}
            {/*    value={policy.snCommercialBusinessInfo.hasSecondaryIndustry}*/}
            {/*    changeEvent={value => doReducePolicyElement({*/}
            {/*        code: 'snCommercialBusinessInfo.hasSecondaryIndustry',*/}
            {/*        value*/}
            {/*    })}*/}
            {/*    allowEdit*/}
            {/*/>*/}
            {/*{*/}
            {/*    policy.snCommercialBusinessInfo.hasSecondaryIndustry &&*/}
            {/*    <FormDropDown*/}
            {/*        fieldCode='snCommercialBusinessInfo.industrySecondaryCode'*/}
            {/*        label="What is the secondary industry of your customer's business?"*/}
            {/*        value={{ code: policy.snCommercialBusinessInfo.industrySecondaryCode }}*/}
            {/*        references={reference?.references['sn-commercial']?.IndustryCodes}*/}
            {/*        referencesEvent={() => reference.doGetReferences('sn-commercial.IndustryCodes')}*/}
            {/*        changeEvent={value => {*/}
            {/*            doReducePolicyElement({*/}
            {/*                code: 'snCommercialBusinessInfo.industrySecondaryCode',*/}
            {/*                value: value.code*/}
            {/*            });*/}
            {/*            doReducePolicyElement({*/}
            {/*                code: 'snCommercialBusinessInfo.industrySecondaryName',*/}
            {/*                value: value.value*/}
            {/*            });*/}
            {/*        }}*/}
            {/*        allowEdit*/}
            {/*    />*/}
            {/*}*/}
            {/*{*/}
            {/*    policy.snCommercialBusinessInfo.hasSecondaryIndustry &&*/}
            {/*    <FormInput*/}
            {/*        fieldCode='snCommercialBusinessInfo.secondaryIndustryCategory'*/}
            {/*        label='Secondary Category'*/}
            {/*        value={policy.snCommercialBusinessInfo.secondaryIndustryCategory}*/}
            {/*        type='text'*/}
            {/*        allowEdit={false}*/}
            {/*    />*/}
            {/*}*/}
            {/*{*/}
            {/*    policy.snCommercialBusinessInfo.hasSecondaryIndustry &&*/}
            {/*    <FormDropDown*/}
            {/*        fieldCode='snCommercialBusinessInfo.percEarningsFromSecIndustryCode'*/}
            {/*        label="What percentage of your customer's earnings comes from the secondary industry?"*/}
            {/*        value={{ code: policy.snCommercialBusinessInfo.percEarningsFromSecIndustryCode }}*/}
            {/*        references={reference?.references['sn-commercial']?.PercEarningsFromSecInd}*/}
            {/*        referencesEvent={() => reference.doGetReferences('sn-commercial.PercEarningsFromSecInd')}*/}
            {/*        changeEvent={value => {*/}
            {/*            doReducePolicyElement({*/}
            {/*                code: 'snCommercialBusinessInfo.percEarningsFromSecIndustryCode',*/}
            {/*                value: value.code*/}
            {/*            });*/}
            {/*        }}*/}
            {/*        allowEdit*/}
            {/*    />*/}
            {/*}*/}
        </Form>
    </>
);

export default ProfileSupernovaCommercialBusinessInfo;

ProfileSupernovaCommercialBusinessInfo.propTypes = {
    policy: PropTypes.object,
    pageCode: PropTypes.string,
    doGetPolicy: PropTypes.func,
    doUpsertPolicy: PropTypes.func,
    tooltipInfo: PropTypes.object,
    allowEdit: PropTypes.bool,
    reference: PropTypes.object,
    validationInfo: PropTypes.object,
    doReducePolicyElement: PropTypes.func,
    doReduceTooltipInfoElement: PropTypes.func,
    doUpsertTooltipInfo: PropTypes.func,
    doUpdatePlace: PropTypes.func,
    context: PropTypes.object.isRequired
};