import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { selectEntity } from '../../selectors/policy/selectEntity';
import { reducePaymentTransferResultReadOnly } from '../../actions/payment/reducePaymentTransferResultReadOnly';
import { reducePaymentTransferResultRecords } from '../../actions/payment/reducePaymentTransferResultRecords';
import { selectPaymentTransferResultRecords } from '../../selectors/payment/selectPaymentTransferResultRecords';
import { reducePaymentTransferResultErrors } from '../../actions/payment/reducePaymentTransferResultErrors';

export function* createPaymentTransferResultRecordSaga() {
    try {
        yield put(reduceLoading({ code: 'createPaymentTransferResultRecord', value: true }));
        const session = yield getSessionSaga();
        const entity = yield select(selectEntity());
        const paymentTransferResultRecords = yield select(selectPaymentTransferResultRecords());

        const response = yield call(fetch, '/api/paymenttransfer/create', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                policyTransactionGuid: entity,
                ...paymentTransferResultRecords[0]
            })
        });

        const result = yield call([response, 'json']);

        if (result.operation && result.operation.status) {
            yield put(reducePaymentTransferResultRecords(result.paymentTransferResultRecords));
            yield put(reducePaymentTransferResultReadOnly(result.isReadOnly));
        } else {
            yield put(reducePaymentTransferResultErrors(result.operation.errors || []));
        }

        yield put(reduceOperation(result.operation));
    }
    catch (error) {
        console.log('Error while calling create Payment Transfer Record API - ', error);
    }
    finally {
        yield put(reduceLoading({ code: 'createPaymentTransferResultRecord', value: false }));
    }
}
